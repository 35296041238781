import React from "react";
import shuffle from "lodash/shuffle";

import api from "@lc/lib/api";

import Home, { HomeProps } from "src/modules/home/IWantToRent";

const HomePage = (props: HomeProps): React.ReactElement => <Home {...props} />;

export async function getStaticProps(): Promise<unknown> {
  const [resExpertise, resAds] = await Promise.all([
    api.getExpertises(),
    api.searchHighlightedAdvertising(),
  ]);
  const { data: expertises, hasError: expertisesError } = resExpertise;
  const { data: advertisings, hasError: advertisingsError } = resAds;

  const errorApi =
    advertisingsError || expertisesError
      ? {
          expertises: expertises.error?.data || null,
          advertisings: advertisings.error?.data || null,
        }
      : null;

  return {
    props: {
      errorApi,
      advertisings: advertisingsError ? [] : shuffle(advertisings),
      expertises: expertisesError ? [] : expertises,
    },
    revalidate: 30,
  };
}

export default HomePage;
