import React, { memo, useRef, useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";

import routes from "@lc/lib/routes";
import { ExpertiseListProps } from "@lc/lib/helpers/expertiseList";

import Button from "@lc/ui/components/Button";
import Link from "@lc/ui/components/Link";
import Text from "@lc/ui/components/Text";
import Divisor from "@lc/ui/components/Divisor";


import Room from "./room";
import { AdvertisingProps } from "../../IWantToRent";

import styles from "./highlights.module.scss";

type Props = {
  advertisings: AdvertisingProps[];
  expertises: ExpertiseListProps[];
};

const Highlights = ({
  expertises,
  advertisings,
}: Props): React.ReactElement => {
  const wrapperRef = useRef<HTMLDivElement[]>([]);
  const listSize = expertises.length - 1;

  const next = (index) => {
    const { width } = wrapperRef.current[index].getBoundingClientRect();
    console.log(
      "wrapperRef.current[index]",
      wrapperRef.current[index].scrollLeft
    );
    wrapperRef.current[index].scrollBy({
      top: 0,
      left: width,
      behavior: "smooth",
    });
  };
  const previous = (index) => {
    const { width } = wrapperRef.current[index].getBoundingClientRect();
    wrapperRef.current[index].scrollBy({
      top: 0,
      left: -width,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const calcu = (event) => {
      const el = event.target;
      const scrollSize = el.scrollWidth - el.clientWidth;
      if (Math.abs(el.scrollLeft) > 0) {
        el.setAttribute("data-left-arrow", true);
      } else {
        el.setAttribute("data-left-arrow", false);
      }
      if (Math.abs(el.scrollLeft) === scrollSize) {
        el.setAttribute("data-right-arrow", false);
      } else {
        el.setAttribute("data-right-arrow", true);
      }
    };
    wrapper?.forEach((el) => {
      el.addEventListener("scroll", calcu);
    });

    return () =>
      wrapper?.forEach((el) => el?.removeEventListener("scroll", calcu));
  }, []);

  return (
    <div className={styles.highlights}>
      {expertises.map((item, index) => {
        const ads = advertisings?.filter((ad) => item.id === ad.expertise_id);

        if (ads.length === 0) return null;
        return (
          <div key={item.name}>
            <Link
              className={styles.marginLeft}
              textProps={{
                styleAs: "body2",
                styleAsOnSm: "h4",
                styleAsOnLg: "h2",
                element: "h2",
              }}
              noUnderline
              href={item.link}
            >
              <span className={styles.active}>{item.name}</span>
            </Link>
            <div className={styles.wrapper}>
              <button
                className={`${styles.arrow} ${styles.left}`}
                onClick={() => previous(index)}
              >
                <AiOutlineArrowLeft />
              </button>
              <div
                ref={(ref) => {
                  wrapperRef.current[index] = ref;
                }}
                className={styles.scroll}
                data-right-arrow="true"
                data-left-arrow="false"
              >
                {ads?.map((ad) => (
                  <div key={ad.id} className={styles.roomPod}>
                    <Room {...ad} />
                  </div>
                ))}
                <div className={styles.roomPod}>
                  <div className={styles.seeMore}>
                    <Link noUnderline href={item.link}>
                      <div>
                        <AiOutlineArrowRight size={36} />
                        <Text styleAs="body2" className={styles.seeMoreText}>
                          Ver mais
                        </Text>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <button
                className={`${styles.arrow} ${styles.right}`}
                onClick={() => next(index)}
              >
                <AiOutlineArrowRight />
              </button>
            </div>
            {index !== listSize ? null : <Divisor />}
          </div>
        );
      })}
      <Button
        href={routes.public.search}
        className={styles.explorerBtn}
        color="secondary"
      >
        <>
          <IoSearch size={24} />
          Explorar mais
        </>
      </Button>
    </div>
  );
};

export default memo(Highlights);
