import React from "react";
import NextHead from "next/head";

const PageTitle = ({ text }: { text: string }): React.ReactElement => {
  const title = text ? `${text} - Let Clinic` : "Let Clinic";
  return (
    <NextHead>
      <title>{title}</title>
    </NextHead>
  );
};

export default PageTitle;
