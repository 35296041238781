import React, { memo, useState } from "react";
import { VscLocation } from "react-icons/vsc";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import routes from "@lc/lib/routes";
import Link from "@lc/ui/components/Link";
import Text from "@lc/ui/components/Text";
import Image from "@lc/ui/components/Image";

import { AdvertisingProps } from "../../IWantToRent";
import styles from "./room.module.scss";

const Room = ({
  city,
  state,
  neighborhood,
  title,
  urls,
  price,
  id,
  period_type
}: AdvertisingProps) => {
  const hasMore = urls.length > 1;

  const [index, setIndex] = useState(0);

  const [showArrow, setShowArrow] = useState({
    left: false,
    right: Boolean(hasMore),
  });

  const next = () => {
    setShowArrow({ left: true, right: true });
    const total = urls.length - 1;
    const nextIndex = index + 1;

    if (index < total) {
      setIndex(nextIndex);
    }

    if (nextIndex === total) {
      setShowArrow({ left: true, right: false });
    }
  };
  const previous = () => {
    setShowArrow({ left: true, right: true });
    const nextIndex = index - 1;
    if (index > 0) {
      setIndex(nextIndex);
    }
    if (nextIndex === 0) {
      setShowArrow({ left: false, right: true });
    }
  };

  return (
    <>
      <div className={styles.imageWrapper}>
        <div className={styles.imageCarousel}>
          {showArrow.left && (
            <button
              className={styles.left}
              onClick={previous}
              aria-label="Próxima imagem"
            >
              <IoChevronBackOutline />
            </button>
          )}
          {showArrow.right && (
            <button
              className={styles.right}
              onClick={next}
              aria-label="Imagem anterior"
            >
              <IoChevronForwardOutline />
            </button>
          )}
        </div>
        <Link href={routes.public.booking(id)} noUnderline naked>
          <>
            {urls.map((item, idx) => (
              <Image
                alt="Imagem em miniatura"
                key={idx}
                data-active={idx === index}
                className={styles.image}
                src={item}
                layout="fill"
                objectFit="cover"
                loading="lazy"
              />
            ))}
            <Text styleAs="h5" className={styles.price}>
              <span>R$ {Math.trunc(Number(price))}</span> / {period_type === 'hours' ? 'hora' : 'período 4h'}
            </Text>
          </>
        </Link>
      </div>
      <Link href={routes.public.booking(id)}>
        <Text styleAs="body2" className={styles.title}>
          {title}
        </Text>
      </Link>
      <Link href={routes.public.booking(id)} noUnderline>
        <Text styleAs="body3" className={styles.address}>
          <VscLocation />
          {neighborhood}, {city}/{state}
        </Text>
      </Link>
    </>
  );
};

export default memo(Room);
