import React from "react";

import Text from "@lc/ui/components/Text";

import styles from "./simple.module.scss";

const SimpleToRent = (): React.ReactElement => (
  <div className={styles.simpleToRent}>
    <Text styleAs="h2">
      É muito simples <b>alugar</b>
    </Text>
    <ol>
      <li>
        <div className={styles.numberItem}>
          <Text noMargin element="span" styleAs="h5" styleAsOnMd="h2">
            01
          </Text>
        </div>
        <Text styleAs="body1" className={styles.text}>
          Use os filtros e encontre o espaço ideal para você
        </Text>
      </li>
      <li>
        <div className={styles.numberItem}>
          <Text noMargin element="span" styleAs="h5" styleAsOnMd="h2">
            02
          </Text>
        </div>
        <Text styleAs="body1" className={styles.text}>
          Defina a data e o período que deseja alugar
        </Text>
      </li>
      <li>
        <div className={styles.numberItem}>
          <Text noMargin element="span" styleAs="h5" styleAsOnMd="h2">
            03
          </Text>
        </div>
        <Text styleAs="body1" className={styles.text}>
          Faça seu cadastro
        </Text>
      </li>
      <li>
        <div className={styles.numberItem}>
          <Text noMargin element="span" styleAs="h5" styleAsOnMd="h2">
            04
          </Text>
        </div>
        <Text styleAs="body1" className={styles.text}>
          Escolha a forma de pagamento
        </Text>
      </li>
      <li>
        <div className={styles.numberItem}>
          <Text noMargin element="span" styleAs="h5" styleAsOnMd="h2">
            05
          </Text>
        </div>
        <Text styleAs="body1" className={styles.text}>
          Clique em finalizar. Prontinho!
        </Text>
      </li>
    </ol>
  </div>
);

export default SimpleToRent;
