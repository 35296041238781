import React from "react";
import classNames from "classnames";
import Link from "@lc/ui/components/Link";

import styles from "./tab.module.scss";

type Props = {
  links: {
    href: string;
    label: string;
    active: boolean;
    new: boolean;
  }[];
};

const HomeTab = ({ links }: Props): React.ReactElement => (
  <div className={styles.tab}>
    {links.map((item) => (
      <div className={styles.new}>
        {item.new && <span className={styles.text}>Novo</span>}
        <Link
          className={styles.link}
          key={item.label}
          noUnderline={item.active}
          textProps={{
            styleAs: "body2",
            styleAsOnSm: "h4",
            styleAsOnLg: "h4",
            element: "h2",
          }}
          href={item.href}
          naked
        >

          <span className={classNames({ [styles.active]: item.active })}>
            {item.label}
          </span>
        </Link>
      </div>

    ))}
  </div>
);

export default HomeTab;
