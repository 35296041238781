import React, { useEffect, useRef, useState } from "react";

import expertiseList from "@lc/lib/helpers/expertiseList";
import logger from "@lc/lib/helpers/logger";
import { useResponsive } from "@lc/lib/hooks/useResponsive";

import Text from "@lc/ui/components/Text";
import HowLCWorks from "@lc/ui/template/home/components/HowLCWorks";
import ExpertiseList from "@lc/ui/template/home/components/ExpertiseList";
import Layout from "@lc/ui/template/Layout";
import SearchForm from "@lc/ui/template/SearchForm";

import PageTitle from "src/components/PageTitle";
import HomeTab from "./components/Tab";

import AdvantagesRent from "./components/AdvantagesRent";
import Banner from "./components/Banner";
import Highlights from "./components/Hightlights";
import SimpleToRent from "./components/SimpleToRent";

import styles from "./iWantToRent.module.scss";

export type AdvertisingProps = {
  id: string;
  city: string;
  expertise_id: string;
  neighborhood: string;
  price: string;
  state: string;
  title: string;
  urls: string[];
  period_type: string;
};

export type HomeProps = {
  advertisings?: AdvertisingProps[];
  expertises?: { id: string; description: string }[];
  errorApi?: unknown;
};
const Home = ({ advertisings, errorApi }: HomeProps): React.ReactElement => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const searchFormRef = useRef<HTMLDivElement>(null);
  const [bannerHeight, setBannerHeight] = useState<string>();
  const { xs, sm } = useResponsive();

  useEffect(() => {
    if (xs && !sm && window?.visualViewport?.height > 660) {
      // banner height will change based on the viewport height of mobile
      setBannerHeight(
        `calc(100vh - ${
          searchFormRef.current.offsetHeight +
          tabsRef.current.offsetHeight +
          160
        }px)`
      );
    }
  }, [sm, xs]);

  useEffect(() => {
    if (errorApi) {
      logger.logError("src/pages/index.js", errorApi);
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, [errorApi]);

  return (
    <>
      <PageTitle text="Alugar" />
      <Layout>
        <section>
          <Banner height={bannerHeight} />
        </section>
        <div className={styles.maxWidthAndAlignCenter}>
          <section ref={searchFormRef} className={styles.searchForm}>
            <SearchForm qaLabel="mobile-banner-search-form" />
          </section>

          <section className={styles.tabs} ref={tabsRef}>
            <HomeTab
              links={[
                {
                  href: "/",
                  label: "Alugar Consultório",
                  active: true,
                  new: false
                },
                {
                  href: "/compartilhar-consultorio",
                  label: "Anunciar Consultório",
                  active: false,
                  new: false
                },
                {
                  href: "https://especialidade.letclinic.com/iagenerativa",
                  label: "IA Generativa",
                  active: false,
                  new: true
                },
              ]}
            />
          </section>

          <section className={styles.section}>
            <ExpertiseList />
          </section>
          <br />
          <section className={styles.section}>
            <Text styleAs="h5" styleAsOnLg="h2" element="h5">
              Espaços para <b>alugar</b>
            </Text>
            <br />
          </section>
          <Highlights expertises={expertiseList} advertisings={advertisings} />
          <section>
            <SimpleToRent />
          </section>
          <section className={styles.section}>
            <AdvantagesRent />
          </section>
        </div>
        <section className={styles.howLcWorks}>
          <HowLCWorks />
        </section>
      </Layout>
    </>
  );
};

export default Home;
