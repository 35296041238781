import React from "react";

import Text from "@lc/ui/components/Text";
import Image from "@lc/ui//components/Image";
import SearchForm from "@lc/ui/template/SearchForm";

import styles from "./banner.module.scss";

type Props = {
  height?: string;
};
const Banner = ({ height = "83vh" }: Props): React.ReactElement => (
  <>
    <div className={styles.bannerWapper} style={{ height: height }}>
      <Image
        className={styles.banner}
        alt="Imagem mostra escritório de um consultório médico"
        priority
        objectFit="cover"
        layout="fill"
        src="images/banner/banner_booker.jpg"
      />
      <div className={styles.maxWidth}>
        <div className={styles.infoSection}>
          <div className={styles.searchForm}>
            <Text styleAs="h5" styleAsOnLg="h3" color="green">
              <b>Encontre o consultório ideal para você</b>
            </Text>
            <SearchForm qaLabel="banner-search-form" />
          </div>
          {/* mobile only */}
          <div className={styles.bannerBg}>
            <Text styleAs="body2" styleAsOnMd="h4" element="h1">
              Atenda seus pacientes
              <Text
                styleAs="h4"
                styleAsOnMd="h2"
                element="p"
                className={styles.underlineText}
              >
                de onde quiser!
              </Text>
            </Text>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Banner;
