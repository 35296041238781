import React, { useState } from "react";
import classNames from "classnames";
import ArrowDownIcon from "@lc/ui/icons/ArrowDown";
import ArrowUpIcon from "@lc/ui/icons/ArrowUp";

import Button from "@lc/ui/components/Button";
import Text from "@lc/ui/components/Text";

import styles from "./advantages.module.scss";

type AdvantagesProps = {
  title: string;
  description: string;
};
const Advantages = ({ title, description }: AdvantagesProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className={styles.title}>
        <Button onClick={() => setOpen(!open)} naked>
          <>
            {open ? (
              <ArrowUpIcon className={styles.icon} />
            ) : (
              <ArrowDownIcon className={styles.icon} />
            )}
            <Text styleAs="body2" styleAsOnSm="h4">
              {title}
            </Text>
          </>
        </Button>
      </div>

      <Text
        className={classNames(styles.description, {
          [styles.show]: open,
          [styles.hide]: !open,
        })}
        styleAs="body2"
        styleAsOnSm="h4"
      >
        {description}
      </Text>
    </>
  );
};

const AdvantagesRent = (): React.ReactElement => (
  <div className={styles.advantagesRent}>
    <Text styleAs="h2">
      Vantagens <b>alugar</b>
    </Text>
    <ul>
      <li>
        <Advantages
          title="Atenda seus pacientes de onde quiser"
          description="Você pode escolher qual cidade e bairro que quer atender."
        />
      </li>
      <li>
        <Advantages
          title="Alugue por períodos de 4h"
          description="Mais flexibilidade. Oferecemos períodos específicos para que você possa evitar qualquer desperdício de tempo. Além é claro, de poder atender em outros consultórios em um único dia."
        />
      </li>
      <li>
        <Advantages
          title="Tenha um consultório completo sem despesas fixas"
          description="Alugar com a Let Clinic é ter liberdade, flexibilidade de atender onde quiser. E sem burocracia. É só escolher o lugar, os períodos, fazer o pagamento e pronto, já pode se preparar para atender seus pacientes com tranquilidade e segurança."
        />
      </li>
      <li>
        <Advantages
          title="Reserve com total segurança"
          description="Nós temos uma equipe dedicada na curadoria que analisa rigorosamente a identificação oficial de cada membro da plataforma e de todos os espaços cadastrados."
        />
      </li>
    </ul>
  </div>
);

export default AdvantagesRent;
